/* Footer */
#footer {
  display: grid;
  place-items: center;
  font-family: "Montserrat";
  font-weight: bold;
  background-color: #efededa0;
  color: black;
  padding: 10px 0;
  z-index: 10;
}
#copy {
  font-weight: normal;
}
.buttons {
  padding: 20px 0;
}
.button {
  margin: 0 5px;
}
#Credits {
  font-family: "Title";
  display: flex;
  margin-bottom: 10px;
}

@media screen and (max-width: 500px) {
  #Credits {
    font-family: "Title";
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
}
