@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Title';
  src: url("./assets/fonts/Mulish-Medium.ttf") format('truetype');
}

@font-face {
  font-family: 'MainTitle';
  src: url("./assets/fonts/RaceSport.ttf") format('truetype');
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body::-webkit-scrollbar {
  display: none;
}


